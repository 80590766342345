










import { Component, Prop, Vue } from 'vue-property-decorator';
import { ISubscriptionTier } from '@/interfaces/ISubscriptionTier';
import TierPanel from '@/components/TierPanel.vue';
import BackButton from '@/components/ui/BackButton.vue';

@Component({
  components: {
    BackButton,
    TierPanel,
  },
})
export default class Tiers extends Vue {
  mounted() {}

  public handleSelect(tier: ISubscriptionTier) {
    if (tier.name === 'Basic') {
      return this.$router.push('/');
    }
    this.$router.push({
      name: 'checkout',
      params: { tier: JSON.stringify(tier) },
    });
  }

  get subscriptions(): ISubscriptionTier[] {
    return [
      {
        name: 'Basic',
        price: 0.0,
        stripeId: 'free',
        _id: '5f3846544f97e6000e1b2d6d',
        content: [
          'Browse our ever-expanding library for free',
          'Preview packs before purchase',
          'Special offers & promotions just for being a member',
          'Upgrade to Core any time',
        ],
        description: 'For anyone just getting started with Drumnow',
        currency: 'USD',
        frequency: 'none',
        ctaText: 'Try Drumnow',
      },
      {
        name: 'Core',
        price: 9.99,
        highlight: true,
        _id: '5f384758ab0546000e0f72eb',
        stripeId: 'price_1KJWHQB5tKve3iIR49sWSGlt',
        content: [
          'Unlimited Streaming of Packs, Loops and Singles',
          '200 Loop and MIDI credits per month',
          '<ul><li>Credits can be used for Loops, Singles, Effects Packs, and MIDI downloads</li> </ul>',
          '<ul><li>MIDI files support Get Good Drums, Steven Slate Drums, Superior Drummer 3 and standard Drumnow mappings. </li> </ul>',
        ],
        description: 'For anyone who wants to get the full Drumnow experience',
        currency: 'USD',
        frequency: 'month',
        ctaText: 'Subscribe',
      },
    ];
  }
}
